import { AxiosError } from "axios";
import { camelizeKeys } from "humps";

import { client, userInfo } from "./users/client";
import {
  SupportRequest,
  BookmarkTaggableAPIType,
  BookmarkTaggableType,
  User,
  ProductMetadatum,
  LeadRequest
} from "./types";
import JSONAPIDeserializer from "./deserializer";

export const getLoginstrategy = (email: string) => {
  return client.post<User>("/web/users/login_strategy", {
    user: {
      email
    }
  });
};

export const sendSamlActivationEmail = (email: string) => {
  return client.post("/web/users/saml_activation_email", {
    user: {
      email
    }
  });
};

export const loginUser = (email: string, password: string) => {
  return client.post<User>("/users/sign_in", {
    session: {
      account: email,
      password
    }
  });
};

export const signOutUser = () => {
  return client.delete("/users/sign_out");
};

export const byPasswordToken = (token: string | null) => {
  return client.post("/web/users/by_password_token", {
    user: {
      password_token: token
    }
  });
};

export const resetPasswordByToken = ({
  password,
  passwordConfirmation,
  token
}: {
  password: string;
  passwordConfirmation: string;
  token: string | null;
}) => {
  const params = {
    session: {
      password,
      password_confirm: passwordConfirmation,
      reset_password_token: token
    }
  };
  return client.put("/users/password", params);
};

export const resetPassword = (email: string) => {
  const formData = new FormData();
  formData.append("session[account]", email);
  return client.post<User>("/users/password", formData, {
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
    }
  });
};

export const userSignup = (userSignupInfo: any) => {
  return client.post("/api/v3/users", {
    user: userSignupInfo
  });
};

// API/V3
interface VendorCallOptions {
  featured?: boolean;
  perPage?: number;
}

interface VendorCallParams {
  featured?: boolean;
  per_page?: number;
}

export const getVendors = (options?: VendorCallOptions) => {
  const params: VendorCallParams = {};
  if (options?.featured) {
    params.featured = options.featured;
  }
  if (options?.perPage) {
    params.per_page = options.perPage;
  }

  return client.get("/api/v3/vendors", { params });
};

export const getPublisher = (slug: string) => {
  return client.get(`/api/v3/vendors/${slug}`);
};

export const getEquities = () => {
  return client.get("/web/ureb/top-equities");
};

export const getMyInfo = () => {
  return client.get("/api/v3/users/me");
};

export const getMySubscriptions = () => {
  return client.get("/api/v3/subscriptions", {
    params: {
      status: ["active", "past_due"]
    }
  });
};

export const getMyInvoices = (pageId: string, perPage?: number) => {
  return client.get("/api/v3/invoices", {
    params: {
      page: pageId,
      per_page: perPage || 10,
      paid: true
    }
  });
};

export const sendInvoiceEmail = (invoiceId: number) => {
  return client.post(`/api/v3/invoices/${invoiceId}/email`);
};

export const getCountries = () => {
  return client.get("/api/v3/countries").then(res => res.data.countries);
};

export const getStates = () => {
  return client.get("/api/v3/states").then(res => res.data.states);
};

export const getProvinces = () => {
  return client.get("/api/v3/provinces").then(res => res.data.provinces);
};

export const getCustomerDetails = (customerId: number) => {
  return client.get(`/api/v3/customers/${customerId}`, {
    params: {
      "embed[]": "cards"
    }
  });
};

export const postCustomerDetails = (customerId: string, payload: any) => {
  return client.patch(`/api/v3/customers/${customerId}`, { ...payload });
};

export const getDataCollections = (productCode: string): any => {
  return client
    .get<{
      plans: any[];
      current_user_subscription: any[];
      datatable_collection: any;
    }>(`/api/v3/datatable_collections/${productCode}`, {
      params: {
        embed: ["plans", "current_user_subscriptions"]
      }
    })
    .then(camelizeKeys);
};

export const getDatabases = (databaseCode: string) => {
  return client.get(`/api/v3/databases/${databaseCode}`, {
    params: {
      embed: ["flags", "plans", "current_user_subscriptions"],
      include: ["documentation"]
    }
  });
};

export const getDatasets = (databaseCode: string) => {
  return client.get("/api/v3/datasets", {
    params: {
      database_code: databaseCode,
      forUsageDocs: true,
      page: 1,
      per_page: 3
    }
  });
};

export const getDatapointsForDatasets = (datasetCode: string) => {
  return client.get("/api/v3/datasets", {
    params: {
      database_code: datasetCode,
      forUsageDocs: true,
      limit: 3
    }
  });
};

export const getDatasetsByIds = (ids: Array<string>) => {
  return client.get("/api/v3/datasets", {
    params: {
      ids
    }
  });
};

export const getDatabasesByIds = (ids: Array<number>) => {
  return client.get("/api/v3/databases", {
    params: {
      ids
    }
  });
};

export const getDatatableCollectionsByIds = (ids: Array<number>) => {
  return client.get("/api/v3/datatable_collections", {
    params: {
      ids
    }
  });
};

export const getDatasetDetailData = (id: string) => {
  return client.get(`/api/v3/datasets/${id}/data`);
};

export const getDatasetSVG = (
  databaseCode: string,
  productCode: string,
  columnIndex: number,
  collapse: any = "",
  transform: any = "",
  limit = 50,
  width = 36,
  height = 14,
  area: any = "",
  startDate = "",
  endDate = ""
) => {
  return client.get(
    `/api/v3/datasets/${databaseCode}/${productCode}/data.svg`,
    {
      params: {
        auth_token: userInfo().api_key,
        column_index: columnIndex,
        collapse,
        transform,
        limit,
        width,
        height,
        area,
        start_date: startDate,
        end_date: endDate
      }
    }
  );
};

export const getCoupon = (
  couponId: string,
  dataType: string,
  databaseId: string
) => {
  return client.get(`/api/v3/coupons/${couponId}`, {
    params: {
      database_id: databaseId,
      product_type: dataType
    }
  });
};

export const getDataTableMeta = (productCode: string) => {
  return client.get(`/api/v3/datatables/${productCode}/metadata`, {
    params: {
      graphable: true,
      api_key: userInfo().api_key
    }
  });
};

export const getRelatedDataCollections = (ids: Array<number> | number) => {
  return client.get("/api/v3/datatable_collections", {
    params: {
      ids
    }
  });
};

export const getRelatedDatabases = (ids: Array<string> | string) => {
  return client.get("/api/v3/databases", {
    params: {
      ids
    }
  });
};

export const getRelatedBundles = (ids: string) => {
  return client.get("/api/v3/bundles", {
    params: {
      ids
    }
  });
};

export const getCapProductMetadatum = (id: number) => {
  return client.get(`/api/v3/cap-product-metadata/${id}`).then(rawData => {
    const deserializer = new JSONAPIDeserializer({
      typeAsAttribute: true,
      keyForAttribute: "camelCase"
    });
    return deserializer.deserialize(rawData.data);
  });
};

export const getPlanCategories = (ids: Array<number>) => {
  return client.get("/api/v3/plan_categories", {
    params: {
      ids
    }
  });
};

export const getPlans = (planIds: Array<number>) => {
  return client.get("/api/v3/plans", {
    params: {
      ids: planIds
    }
  });
};

export const getPlan = (planId: string) => {
  return client.get(`/api/v3/plans/${planId}`);
};

export const getMoreDataFeeds = (productIds: Array<string>) => {
  return client.get("/api/v3/datatable_collections", {
    params: {
      ids: productIds
    }
  });
};

export const getProductMeta = (productMetaId: string) => {
  return client.get(`/api/v3/product-metadata/${productMetaId}`);
};

/**
 * Similar to `getProductMeta` but normalized from raw JSONAPI response
 * @param productMetadatumId id of product-metadatum to fetch
 * @returns normalized `ProductMetadatum` data
 */
export const getProductMetadatum: (
  productMetadatumId: string
) => Promise<ProductMetadatum> = (productMetadatumId: string) => {
  return client
    .get(`/api/v3/product-metadata/${productMetadatumId}`)
    .then(rawData => {
      const deserializer = new JSONAPIDeserializer({
        keyForAttribute: "camelCase"
      });
      return deserializer.deserialize(rawData.data);
    });
};

export const getProductSupportingDocuments = (documentId: number) => {
  return client
    .get(`/api/v3/product-supporting-documents/${documentId}`)
    .then(rawData => {
      const deserializer = new JSONAPIDeserializer({
        typeAsAttribute: true,
        keyForAttribute: "camelCase"
      });
      return deserializer.deserialize(rawData.data);
    });
};

export const getProductVendor = (vendorId: string) => {
  return client.get(`/api/v3/vendors/${vendorId}`);
};

export const getLicenseTerms = (licenseId: number) => {
  return client.get(`/web/licences/${licenseId}`);
};

export const getProductsByDataTable = (IDs: Array<string>) => {
  return client.get("/api/v3/products", {
    params: {
      ids: IDs,
      include: "product-metadatum"
    }
  });
};

export const getRecommendProducts = (productId: string) => {
  return client.get("/api/v3/products", {
    params: {
      current_product_id: productId,
      q: "recommendation"
    }
  });
};

export const getAllTheVendorsData = (IDs: Array<string>) => {
  return client.get("/api/v3/vendors", {
    params: {
      ids: IDs
    }
  });
};

export const getMyOrganizations = () => {
  return client.get("/api/v3/organizations");
};

export const inviteOrganizationUsers = (
  organizationId: number,
  emails: string[]
) => {
  return client.post(`/api/v3/organizations/${organizationId}/invite_users`, {
    invite_emails: emails.join(",")
  });
};

export const getOrganizationDatabases = (ids: Array<number>) => {
  return client.get("/api/v3/organization_databases", {
    params: {
      ids
    }
  });
};

export const getOrganizationTeams = (ids: Array<number>) => {
  return client.get("/api/v3/organization_teams", {
    params: {
      ids
    }
  });
};

export const addOrganizationTeams = ({
  name,
  organization_id,
  create_at = null,
  organization_invoice_id = null
}: {
  create_at?: string | null;
  name: string;
  organization_id: string;
  organization_invoice_id?: number | null;
}) => {
  return client.post("/api/v3/organization_teams", {
    organization_team: {
      name,
      organization_id,
      create_at,
      organization_invoice_id
    }
  });
};

export const getUserPermissionDetailById = (id: string) => {
  return client.get(`/api/v3/organization_teams/${id}`);
};

export const deletePermissionById = (id: string) => {
  return client.delete(`/api/v3/organization_teams/${id}`);
};

export const getOrganizationTeamInvoices = (id: number) => {
  return client
    .get("/api/v3/organization_team_invoices", {
      params: {
        "filter[organizationId]": id
      }
    })
    .then(rawData => {
      const deserializer = new JSONAPIDeserializer({
        keyForAttribute: "camelCase"
      });
      return deserializer.deserialize(rawData.data);
    });
};

export const getOrganizationUsers = (ids: Array<string>) => {
  return client.get("/api/v3/organization_users", {
    params: {
      ids
    }
  });
};

export const removeUserById = (id: string) => {
  return client.delete(`/api/v3/organization_users/${id}`);
};

export const getOrganizationTeamsUsers = (ids: Array<string>) => {
  return client.get("/api/v3/organization_team_users", {
    params: {
      ids
    }
  });
};

export const getOrganizationTeamDatabases = (ids: Array<string>) => {
  return client.get("/api/v3/organization_team_databases", {
    params: {
      ids
    }
  });
};

export const removeOrganizationTeamDatabase = (id: string) => {
  return client.delete(`/api/v3/organization_team_databases/${id}`);
};

export const addOrganizationTeamUser = (payload: {
  [_: string]: string | number | null;
}) => {
  return client.post("/api/v3/organization_team_users", {
    organization_team_user: payload
  });
};

export const addOrganizationTeamDatabase = (payload: {
  [_: string]: string | number | null;
}) => {
  return client.post("/api/v3/organization_team_databases", {
    organization_team_database: payload
  });
};

export const addOrganizationUsers = (payload: {
  [_: string]: string | number | null;
}) => {
  return client.post("/api/v3/organization_team_users", payload);
};

export const removeOrganizationUser = (id: string | number | null) => {
  return client.delete(`/api/v3/organization_team_users/${id}`);
};

export const getOrganizationNewUserApiKey = (organizationUserId: string) => {
  return client.patch(
    `/api/v3/organization_users/${organizationUserId}/update_api_key`
  );
};

export const getOrganizationApiKeys = (organizationId: number) => {
  return client
    .get("/api/v3/organization-apis", {
      params: {
        "filter[organization-id]": organizationId
      }
    })
    .then(rawData => {
      const deserializer = new JSONAPIDeserializer({
        typeAsAttribute: true,
        keyForAttribute: "camelCase"
      });
      return deserializer.deserialize(rawData.data);
    });
};

export const getOrganizationApiKey = (keyId: string) => {
  return client.get(`/api/v3/organization-apis/${keyId}`).then(rawData => {
    const deserializer = new JSONAPIDeserializer({
      typeAsAttribute: true,
      keyForAttribute: "camelCase"
    });
    return deserializer.deserialize(rawData.data);
  });
};

export const deleteOrganizationApiKey = (apiKeyId: string) => {
  return client.delete(`/api/v3/organization-apis/${apiKeyId}`);
};

export const createOrganizationApiKey = (
  name: string,
  orgId: string | number
) => {
  return client.post("/api/v3/organization-apis", {
    data: {
      attributes: {
        name,
        "organization-id": orgId
      },
      type: "organization-apis"
    }
  });
};

export const updateOrganizationApiKeyTeam = (
  id: string | number,
  teamIds: (string | number)[]
) => {
  return client.patch(`/api/v3/organization-apis/${id}`, {
    data: {
      id,
      attributes: {
        "organization-team-ids": teamIds
      }
    }
  });
};

export const inviteUsersToOrganization = (
  organizationId: string,
  emails: string
) => {
  return client.post(`/api/v3/organizations/${organizationId}/invite_users`, {
    invite_emails: emails
  });
};

export const addUserToOrganizationTeam = (
  userId: number,
  orgTeamId: number
) => {
  return client.post("/api/v3/organization_team_users", {
    organization_team_user: {
      organization_user_id: userId,
      organization_team_id: orgTeamId
    }
  });
};

export const removeUserFromOrganizationTeam = (
  organizationTeamUserId: string
) => {
  return client.delete(
    `/api/v3/organization_team_users/${organizationTeamUserId}`
  );
};

export const removeUserFromOrganization = (organizationUserId: number) => {
  return client.delete(`/api/v3/organization_users/${organizationUserId}`);
};

export const getOrganizationTeamUsers = (ids: number[]) => {
  const formattedQueryParams = ids.map(id => `ids[]=${id}`);
  return client.get(
    `/api/v3/organization_team_users?${formattedQueryParams.join("&")}`
  );
};

export const getproductCategories = (parentId: any) => {
  return client.get("/web/product-categories", {
    params: {
      "filter[parent_id]": parentId
    }
  });
};

export const addCardToServer = (customerId: string, token: any) => {
  return client.post(`/api/v3/customers/${customerId}/add_card`, {
    add_card: {
      stripe_token: token.id
    }
  });
};

export const postSubscription = (payload: any) => {
  return client.post("/api/v3/subscriptions", {
    ...payload
  });
};

export const postReSubscription = (id: string) => {
  return client.post(`/api/v3/subscriptions/${id}`);
};

export const removeSubscription = (id: string) => {
  return client.delete(`/api/v3/subscriptions/${id}`);
};

export const getInvoiceDetailsById = (invoiceId: string) => {
  return client.get(`/api/v3/invoices/${invoiceId}`);
};

export const getDataTables = (tableName: string) => {
  return client.get(`/api/v3/datatables/${tableName}/metadata`, {
    params: {
      api_key: userInfo().api_key,
      show_all_columns: true
    }
  });
};

export const postDatatableCollections = (
  organizationTeamId: string,
  payload: any
) => {
  return client.post(
    "/manage/datatable-collection-slices",
    { ...payload },
    {
      headers: {
        "Content-type": "application/vnd.api+json"
      },
      params: {
        organization_team_id: organizationTeamId
      }
    }
  );
};

export const getEnchiridionData = async (dataType: string) => {
  return client.get(`/web/enchiridion/${dataType}`).then(response => {
    const deserializer = new JSONAPIDeserializer({ typeAsAttribute: true });
    return deserializer.deserialize(response.data);
  });
};

export const getAssetsUnderManagement = () => {
  return client.get("/web/enchiridion/assets_under_management");
};

export const getBusinessTypes = () => {
  return client.get("/web/enchiridion/business_types");
};

export const getNumberOfEmployees = () => {
  return client.get("/web/enchiridion/number_of_employees");
};

export const getBusinessPlannedDataUsages = () => {
  return client.get("/web/enchiridion/business_planned_data_usages");
};

export const getAcademicPlannedDataUsages = () => {
  return client.get("/web/enchiridion/academic_planned_data_usages");
};

export const getPersonalPlannedDataUsages = () => {
  return client.get("/web/enchiridion/personal_planned_data_usages");
};

export const getPrivacySetting = () => {
  return client.get("/web/privacy-setting");
};

export const getSalesManagers = () => {
  return client.get("/manage/users/sales_managers");
};

export const onboardingBusinessUser = () => {
  return client.get("/manage/business_user_onboarding/submit", {
    params: {
      api_key: userInfo().api_key
    }
  });
};

export const searchByAPIKeys = (inputAPIKey: string) => {
  return client.post("/manage/users/with_products_by_api_keys", {
    params: {
      api_key: inputAPIKey || userInfo().api_key
    }
  });
};

export const getTimeseriesProducts = () => {
  return client.get("/manage/sources");
};

export const getTableProducts = () => {
  return client.get("/manage/datatable-collections", {
    params: {
      "fields[datatable-collections]": "name,code,product-type"
    }
  });
};

const bookmarkTaggableTypeToAPIType: {
  [key in BookmarkTaggableType]: BookmarkTaggableAPIType;
} = {
  DatatableCollection: "datatable-collection",
  Database: "database",
  Dataset: "dataset"
};

export const bookmarkAPITypeToTaggableType: {
  [key in BookmarkTaggableAPIType]: BookmarkTaggableType;
} = {
  "datatable-collection": "DatatableCollection",
  database: "Database",
  dataset: "Dataset"
};

export const getMyBookmarks = () => {
  return client.get("/api/v3/bookmarks");
};

export const addToBookmarks = (
  taggableType: BookmarkTaggableType,
  taggedId: number
) => {
  return client.post("/api/v3/bookmarks", {
    tagged_type: bookmarkTaggableTypeToAPIType[taggableType],
    tagged_id: taggedId
  });
};

export const removeFromBookmarks = (
  taggableType: BookmarkTaggableType,
  id: number
) => {
  return client.delete(
    `/api/v3/bookmarks/${bookmarkTaggableTypeToAPIType[taggableType]}/${id}`
  );
};

export const getOrganizations = () => {
  return client.get("/manage/organizations").then(response => {
    const deserializer = new JSONAPIDeserializer({ typeAsAttribute: true });
    return deserializer.deserialize(response.data);
  });
};

export const getManageOrganizationById = (id: string) => {
  return client.get(`/manage/organizations/${id}`, {
    params: {
      include:
        "organization-users,organization-users.user,organization-teams,organization-teams.organization-users,organization-teams.organization-team-invoice"
    }
  });
};

export const getOrganizationUsersByIds = (ids: Array<number>) => {
  return client.get("/api/v3/organization_users", {
    params: {
      ids
    }
  });
};

export const agreeTermsOfUseById = (id: string) => {
  return client.post(`/api/v3/organizations/${id}/accept_terms`);
};

export const getOrganizationById = (id: number) => {
  return client.get(`/api/v3/organizations/${id}`);
};

export const getOrganizationOnboarding = (data: {
  organization: {
    name: string;
    id?: string;
    type: string;
    supports_catalogue_products: boolean;
  };
  user_segmentation: {
    assets_under_management_id?: string;
    business_type_id?: string;
    number_of_employee_id?: string;
    organization_name: string;
    planned_data_usage_ids: string[];
  };
  users: {
    first_name: string;
    last_name: string;
    email: string;
    set_as_admin: boolean;
    skip_user_creation: boolean;
  }[];
  products: {
    access_start: string;
    auto_renewal?: boolean;
    business_contacts?: string[];
    technical_contacts?: string[];
    code?: string;
    delivery_frequency: string;
    history_option: string;
    product_type: string;
    sales_manager_id: string;
    type: string;
    urgency: string;
    years_commitment?: number;
    comments: string | null;
    organization_team_invoice_id?: string;
    users: string[];
  }[];
}) => {
  return client.post("/manage/organization_onboarding/submit", data, {
    params: {
      api_key: userInfo().api_key
    }
  });
};

export const getDatatableCollections = () => {
  return client.get("/manage/datatable-collections", {
    params: {
      "fields[datatable-collections]": "name,code,product-type"
    }
  });
};

export const getTableData = (
  code: string,
  rowNum: number,
  nextCursorId = ""
) => {
  let payload;
  if (nextCursorId !== "") {
    payload = {
      "qopts.per_page": rowNum,
      "qopts.cursor_id": nextCursorId,
      api_key: userInfo().api_key
    };
  } else {
    payload = {
      "qopts.per_page": rowNum,
      api_key: userInfo().api_key
    };
  }
  return client.get(`/api/v3/datatables/${code}`, { params: payload });
};

export const getBulkDownloadData = (code: string, params?: URLSearchParams) => {
  params?.set("api_key", params?.get("api_key") || userInfo().api_key);
  params?.set("qopts.export", params?.get("qopts.export") ?? "true");

  return client.get(`/api/v3/datatables/${code}?${params?.toString()}`);
};

export const getTeamInvoice = () => {
  return client.get("/manage/organization-team-invoices", {
    params: {
      include:
        "organization,product,organization-team,organization-team.organization-databases",
      "fields[organizations]": "name",
      "fields[products]": "code,datatables",
      "fields[organization-team-invoices]":
        "id,product,organization,organization-team",
      "filter[product_type]": "DatatableCollection",
      "filter[updated_in_manager]": "false"
    }
  });
};

export const updateMyInfo = (updatedInfo: object) => {
  return client.put("/api/v3/users/me", { user: updatedInfo });
};

export const deleteMyAccount = () => {
  return client.delete("/api/v3/users/me");
};

export const postBookMarks = (taggedId: number, taggedType: string) => {
  return client.post("/api/v3/bookmarks", {
    tagged_id: taggedId,
    tagged_type: taggedType
  });
};

export const postLead = (payload: LeadRequest) => {
  const formData = {
    data: {
      attributes: {
        ...payload
      },
      type: "leads"
    }
  };

  return client.post<LeadRequest>("/api/v3/leads", JSON.stringify(formData), {
    headers: {
      "Content-type": "application/vnd.api+json; charset=UTF-8"
    }
  });
};

export const updateAPIKey = () => {
  return client.patch("/api/v3/users/update_api_key");
};

export const getRecentProducts = () => {
  return client.get("/api/v3/products?q=recent").then(response => {
    const deserializer = new JSONAPIDeserializer({ typeAsAttribute: true });
    return deserializer.deserialize(response.data);
  });
};

export const getDatatableCollectionsFromIds = (ids: number[] | number) => {
  return client.get("/api/v3/datatable_collections", {
    params: {
      ids
    }
  });
};

export const getDatabasesFromIds = (ids: number[]) => {
  const params = mapIdsToParams(ids);
  return client.get(`/api/v3/databases?${params}`);
};

export const getSessionStatus = () => {
  return client
    .get<number>("/check_session_timeout")
    .then(response => response.data)
    .catch((error: AxiosError) =>
      error.response?.status === 401 || error.response?.status === 500
        ? 0
        : 1000
    );
};

export const touchSessionStatus: () => Promise<number> = () => {
  return client.get("/touch_session_timeout");
};

// WEB
export const getAnnouncements = () => {
  return client.get("/web/podgeboard/announcements");
};

export const getTopEquities = () => {
  return client.get("/web/ureb/top-equities");
};

export const getResourcesData = () => {
  return client
    .get("/web/podgeboard/resource-hubs")
    .then(res => res.data)
    .then(data => {
      return new JSONAPIDeserializer({
        typeAsAttribute: true,
        // @ts-ignore
        "podgeboard-category": {
          valueForRelationship(relationship: any) {
            return relationship.id;
          }
        }
      }).deserialize(data);
    });
};

export const getResourceCategory = () => {
  return client
    .get("/web/podgeboard/podgeboard-categories?type=ResourceHub")
    .then(res => res.data)
    .then(data =>
      new JSONAPIDeserializer({
        typeAsAttribute: true
      }).deserialize(data)
    );
};

export const getResourceDetail = (slug: string) => {
  return client
    .get(`/web/podgeboard/resource-hubs/${slug}`)
    .then(res => res.data)
    .then(data =>
      new JSONAPIDeserializer({
        typeAsAttribute: true
      }).deserialize(data)
    );
};

export const getHelpscoutArticles = () => {
  return client.get("/web/helpscout-articles").then(res => res.data);
};

export const getCategoryHelpscoutArticles = (catergoryId: string) => {
  return client
    .get(`/web/helpscout-categories/${catergoryId}/helpscout-articles`)
    .then(res => res.data);
};

export const getHelpscoutCategories = () => {
  return client.get("/web/helpscout-categories").then(res => res.data);
};

export const triggerWelcomeEmail = (
  organizationId: string,
  userId: string,
  code: string,
  type: string
) => {
  return client.post(
    "/manage/organization_onboarding/trigger_welcome_email",
    {
      organization_id: organizationId,
      user_id: userId,
      product: {
        type,
        code
      }
    },
    {
      params: {
        api_key: userInfo().api_key
      }
    }
  );
};

export const getClientEmailCsvFromProductCodes = (productCodes: string) => {
  return client.get(`/manage/client_email_list?product_codes=${productCodes}`, {
    params: {
      api_key: userInfo().api_key
    }
  });
};

export const createSupportRequest = ({
  name,
  email,
  referrer,
  categoryId,
  comment,
  productCode,
  productType
}: SupportRequest) => {
  const formData = {
    data: {
      attributes: {
        name,
        email,
        referrer,
        "category-id": categoryId,
        comment,
        "product-code": productCode,
        "product-type": productType
      },
      type: "support-requests"
    }
  };

  return client.post<SupportRequest>(
    "/web/support-requests",
    JSON.stringify(formData),
    {
      headers: {
        "Content-type": "application/vnd.api+json; charset=UTF-8"
      }
    }
  );
};

// UTILITY FUNCTIONS
// refactor with reduce
const mapIdsToParams = (ids: number[]) => {
  const params = ids.map(id => `ids[]=${id}`);
  return params.join("&");
};
